/* eslint-disable no-param-reassign */
window.o_apps_init = (deps) => {
  /* deps should be
           {
             'document': htmlDocument,
             'httpRequest': httpRequest,
             'cookieBanner': () => window.cookieBanner,
             'cmp': () => window.cmp,
             'eventQBus': window.o_global.eventQBus,
             'appsAndroid': appsAndroid,
             'privacyPage': () => window.o_privacyPage,
             'wishlist': () => window.o_wishlist,
             'htmlLocation': htmlLocation,
             'lhotseExactag': windowLhotseExactag,
             'exactag': windowExactag,
             'userAgent': userAgent,
             'features': features
         } */

  const featureToggle = deps.features || {
    shareNative: true,
  };

  const ftapps = {
    platform: {
      ios: "ios",
      android: "android",
    },
  };
  const downloadImagePromises = {};

  function isGoogleTrackingAllowed() {
    if (deps.cmp && deps.cmp() && deps.cmp().getAppGoogleTrackingConsent) {
      return deps.cmp().getAppGoogleTrackingConsent();
    }
    return Promise.resolve(false);
  }

  async function isAdjustTrackingAllowed() {
    const cmpReady = new Promise((isReady) => {
      deps.eventQBus.onModuleInitialized("apps.global.assets", ["eprivacy.privacy.cmp"], isReady);
    });

    await cmpReady;

    if (deps.cmp && deps.cmp() && deps.cmp().getAdjustTrackingConsent) {
      return deps.cmp().getAdjustTrackingConsent();
    }

    return Promise.resolve(false);
  }
  function shouldPassFirebaseEventToClients(apiInstance) {
    if (
      apiInstance.appType() === ftapps.platform.android &&
      !apiInstance.compareVersionsGreaterEqual(apiInstance.appVersion(), "12.5.0")
    ) {
      return isGoogleTrackingAllowed();
    }
    return Promise.resolve(true);
  }

  function validateParameterType(parameter, expectedType, actualType, errorObject) {
    if (expectedType !== actualType) {
      errorObject[parameter] = "expected "
        .concat(expectedType)
        .concat(" but received ")
        .concat(actualType);
    }
  }

  function validateFirebaseParams(eventParams, error) {
    if (eventParams !== null && typeof eventParams === "object") {
      if (deps.firebaseParameterKey.kFIRParameterItemID in eventParams) {
        const itemId = deps.firebaseParameterKey.kFIRParameterItemID;
        const actualType = typeof eventParams[itemId];
        validateParameterType(
          deps.firebaseParameterKey.kFIRParameterItemID,
          "string",
          actualType,
          error,
        );
      }
      if (deps.firebaseParameterKey.kFIRParameterItemName in eventParams) {
        const itemName = deps.firebaseParameterKey.kFIRParameterItemName;
        const actualType = typeof eventParams[itemName];
        validateParameterType(
          deps.firebaseParameterKey.kFIRParameterItemName,
          "string",
          actualType,
          error,
        );
      }

      if (deps.firebaseParameterKey.kFIRParameterItemCategory in eventParams) {
        const category = deps.firebaseParameterKey.kFIRParameterItemCategory;
        const actualType = typeof eventParams[category];
        validateParameterType(
          deps.firebaseParameterKey.kFIRParameterItemCategory,
          "string",
          actualType,
          error,
        );
      }

      if (deps.firebaseParameterKey.kFIRParameterQuantity in eventParams) {
        const quantity = deps.firebaseParameterKey.kFIRParameterQuantity;
        const actualType = typeof eventParams[quantity];
        validateParameterType(
          deps.firebaseParameterKey.kFIRParameterQuantity,
          "number",
          actualType,
          error,
        );
      }

      if (deps.firebaseParameterKey.kFIRParameterValue in eventParams) {
        const value = deps.firebaseParameterKey.kFIRParameterValue;
        const actualType = typeof eventParams[value];
        validateParameterType(
          deps.firebaseParameterKey.kFIRParameterValue,
          "number",
          actualType,
          error,
        );
      }
    }
  }

  function callFirebaseBridge(apiInstance, firebasePayloadJSON) {
    if (
      apiInstance.appType() === ftapps.platform.android &&
      deps.appsAndroid &&
      deps.appsAndroid.trackFirebase
    ) {
      const payloadJSON = JSON.stringify(firebasePayloadJSON);
      deps.appsAndroid.trackFirebase(payloadJSON);
    } else if (apiInstance.appType() === ftapps.platform.ios) {
      apiInstance.iosCallbacks.trackFirebase(
        apiInstance,
        deps.webkitMessageHandlers,
        firebasePayloadJSON,
      );
    }
  }

  function consentGenerationBridge(apiInstance) {
    if (
      apiInstance.appType() === ftapps.platform.android &&
      deps.appsAndroid &&
      deps.appsAndroid.onConsentGenerationFinish
    ) {
      deps.appsAndroid.onConsentGenerationFinish();
    } else if (apiInstance.appType() === ftapps.platform.ios) {
      apiInstance.iosCallbacks.onConsentGenerationFinish(apiInstance, deps.webkitMessageHandlers);
    }
  }

  function onInvalidOneTrustVendorListBridge(apiInstance, exceptionMessage) {
    if (
      apiInstance.appType() === ftapps.platform.android &&
      deps.appsAndroid &&
      deps.appsAndroid.onInvalidOneTrustVendorList
    ) {
      deps.appsAndroid.onInvalidOneTrustVendorList(exceptionMessage);
    } else if (
      apiInstance.appType() === ftapps.platform.ios &&
      apiInstance.iosCallbacks &&
      deps.webkitMessageHandlers &&
      deps.webkitMessageHandlers.onInvalidOneTrustVendorList
    ) {
      apiInstance.iosCallbacks.onInvalidOneTrustVendorList(
        deps.webkitMessageHandlers,
        exceptionMessage,
      );
    }
  }

  function optOutAllForOneTrust(apiInstance) {
    if (
      apiInstance.appType() === ftapps.platform.android &&
      deps.appsAndroid &&
      deps.appsAndroid.onOptOutAllVendors
    ) {
      deps.appsAndroid.onOptOutAllVendors();
    } else if (
      apiInstance.appType() === ftapps.platform.ios &&
      apiInstance.iosCallbacks &&
      apiInstance.iosCallbacks.onOptOutAllVendors &&
      deps.webkitMessageHandlers &&
      deps.webkitMessageHandlers.onOptOutAllVendors
    ) {
      apiInstance.iosCallbacks.onOptOutAllVendors(deps.webkitMessageHandlers);
    }
  }

  /**
   * @module o_apps
   */
  const api = {
    /**
     * @alias module:o_apps.appVersion
     * @returns {(string|undefined)} the currently running apps version as a string or undefined, if not running in an app
     */
    appVersion() {
      if (this.cookieWithName("appVersion") === undefined) {
        return undefined;
      }
      return this.cookieWithName("appVersion").split("=")[1];
    },

    /**
     * @alias module:o_apps.appType
     * @returns {(string|undefined)} the currently running apps operating system type, currently android or ios, as a
     * string or undefined, if not running in an app
     */
    appType() {
      if (this.cookieWithName("appType") === undefined) {
        return undefined;
      }
      return this.cookieWithName("appType").split("=")[1];
    },

    cookieWithName(name) {
      return this.splitCookies().filter((c) => c.indexOf(name) === 0)[0];
    },

    getOnexAssignment(onexNumber, onexValue, callback) {
      const xmlhttp = deps.httpRequest();
      const url = `${window.location.protocol}//${window.location.hostname}/onex/assignments/`;

      function onReadyStateChangeHandler() {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (this.status === 200) {
            if (this.responseText === null || this.responseText === undefined) {
              callback(false);
              return;
            }

            const { onexes } = JSON.parse(this.responseText);

            if (onexes === null || onexes === undefined) {
              callback(false);
              return;
            }

            const foundOnex = onexes.filter((onex) => onex.n === onexNumber);

            if (
              foundOnex !== null &&
              foundOnex !== undefined &&
              foundOnex.length > 0 &&
              foundOnex[0].v === onexValue
            ) {
              callback(true);
            } else {
              callback(false);
            }
          } else {
            callback(false);
          }
        }
      }

      xmlhttp.onreadystatechange = onReadyStateChangeHandler;

      xmlhttp.open("GET", url, true);
      xmlhttp.send(null);
    },

    osVersionAndroid() {
      if (deps.userAgent.indexOf("Android") !== -1 && deps.userAgent.indexOf("Windows") === -1) {
        return parseFloat(deps.userAgent.slice(deps.userAgent.indexOf("Android") + 8));
      }

      return undefined;
    },

    osVersionIos() {
      return (
        parseFloat(
          `${
            (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(deps.userAgent) || [
              0,
              "",
            ])[1]
          }`.replace("_", "."),
        ) || undefined
      );
    },

    shouldDisplaySmartAppBanner() {
      const MIN_ANDROID_VERSION = 6;
      const MIN_IOS_VERSION = 13;

      if (this.runningInApp()) {
        return false;
      }

      const osVersionIos = api.osVersionIos();
      const osVersionAndroid = api.osVersionAndroid();

      if (osVersionAndroid !== undefined && osVersionAndroid >= MIN_ANDROID_VERSION) {
        return true;
      }
      return osVersionIos !== undefined && osVersionIos >= MIN_IOS_VERSION;
    },

    extractFocusedMetaTags() {
      if (!api.runningInApp()) return;
      const topBarMetaContent = document.head.querySelector(
        "meta[name=apps-focused-layout-top-bar-visibility]",
      )?.content;
      const bottomBarMetaContent = document.head.querySelector(
        "meta[name=apps-focused-layout-bottom-bar-visibility]",
      )?.content;
      const titleMetaContent = document.head.querySelector(
        "meta[name=apps-focused-layout-title]",
      )?.content;
      const animatedOnScrollMetaContent = document.head.querySelector(
        "meta[name=apps-focused-layout-animated-on-scroll]",
      )?.content;

      const metaJson = {
        topBarVisibility: topBarMetaContent,
        bottomBarVisibility: bottomBarMetaContent,
        title: titleMetaContent,
        animatedOnScroll: animatedOnScrollMetaContent,
      };
      this.sendFocusedMetaJson(metaJson);
    },

    sendFocusedMetaJson(metaJson) {
      if (!api.runningInApp()) return;
      if (
        (api.appType() === ftapps.platform.android &&
          api.appVersion() &&
          !api.compareVersionsGreaterEqual(api.appVersion(), "13.0.0")) ||
        (api.appType() === ftapps.platform.ios &&
          api.appVersion() &&
          !api.compareVersionsGreaterEqual(api.appVersion(), "11.12.0"))
      ) {
        metaJson = {};
      }
      if (
        api.appType() === ftapps.platform.android &&
        deps.appsAndroid &&
        deps.appsAndroid.onFocusedMetaTagsReceived
      ) {
        deps.appsAndroid.onFocusedMetaTagsReceived(JSON.stringify(metaJson));
      }
      if (
        api.appType() === ftapps.platform.ios &&
        deps.webkitMessageHandlers &&
        deps.webkitMessageHandlers.onFocusedMetaTagsReceived
      ) {
        deps.webkitMessageHandlers.onFocusedMetaTagsReceived.postMessage(metaJson);
      }
    },

    updateSearchTerm() {
      if (api.runningInApp()) {
        const divReptileSearchTerm = deps.document.getElementById("reptile-searchterm");
        if (divReptileSearchTerm !== null && divReptileSearchTerm !== undefined) {
          api.sendSearchTerm(divReptileSearchTerm);
        } else {
          api.invokeOnSearchTermReceived("");
        }
      }
    },

    sendSearchTerm(divReptileSearchTerm) {
      let result = "";
      const searchTerm = divReptileSearchTerm.getAttribute("data-searchterm");
      if (searchTerm !== null) {
        result = searchTerm;
      }
      api.invokeOnSearchTermReceived(result);
      return result;
    },

    invokeOnSearchTermReceived(searchTerm) {
      if (
        api.appType() === ftapps.platform.ios &&
        deps.webkitMessageHandlers &&
        deps.webkitMessageHandlers.onSearchTermReceived &&
        this.iosCallbacks &&
        this.iosCallbacks.onSearchTermReceived
      ) {
        this.iosCallbacks.onSearchTermReceived(deps.webkitMessageHandlers, searchTerm);
      } else if (
        api.appType() === ftapps.platform.android &&
        deps.appsAndroid &&
        deps.appsAndroid.onSearchTermReceived
      ) {
        deps.appsAndroid.onSearchTermReceived(searchTerm);
      }
    },

    shouldDisplaySmartAppBannerWithTracking() {
      const shouldDisplaySmartBanner = this.shouldDisplaySmartAppBanner();

      return {
        shouldDisplaySmartBanner,
        adjustLink:
          "https://frj4.adj.st/www.otto.de/extern/?page=%2F&otto_deep_link=https%3A%2F%2Fwww.otto.de%2Fextern%2F%3Fpage%3D%252F&adjust_t=33v0zew_uu2qg33&adjust_deeplink=otto%3A%2F%2Fwww.otto.de%2Fextern%2F%3Fpage%3D%252F",
      };
    },

    shouldShowWebCookieBanner() {
      return !api.runningInApp();
    },

    /**
     * compares, if version v1 is greater or equal to v2
     */
    compareVersionsGreaterEqual(v1, v2) {
      const v1parts = v1.split(".");
      const v2parts = v2.split(".");
      const maxLen = Math.max(v1parts.length, v2parts.length);
      let part1;
      let part2;
      let cmp = 0;

      for (let i = 0; i < maxLen && !cmp; i += 1) {
        part1 = parseInt(v1parts[i], 10) || 0;
        part2 = parseInt(v2parts[i], 10) || 0;
        if (part1 < part2) cmp = 1;
        if (part1 > part2) cmp = -1;
      }

      return cmp <= 0;
    },

    executeXMLHttpPostRequest(url, data) {
      const xhr = deps.httpRequest();
      const random = String(new Date().getTime());

      xhr.open("POST", `${url}?cachebust=${random}`, true);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.send(data);
    },

    downloadImage(imgUrl, openImageOnSuccess) {
      const imageDownloadCallbackPathAndName = "o_apps.downloadImageResult";

      if (
        api.appType() === ftapps.platform.android &&
        api.compareVersionsGreaterEqual(api.appVersion(), "12.4.0") &&
        deps.appsAndroid &&
        deps.appsAndroid.downloadImage
      ) {
        return new Promise((resolve, reject) => {
          downloadImagePromises[imgUrl] = { resolve, reject };
          deps.appsAndroid.downloadImage(
            imgUrl,
            openImageOnSuccess,
            imageDownloadCallbackPathAndName,
          );
        });
      }
      if (
        api.appType() === ftapps.platform.ios &&
        api.compareVersionsGreaterEqual(api.appVersion(), "11.10.0") &&
        deps.webkitMessageHandlers &&
        deps.webkitMessageHandlers.downloadImage &&
        deps.webkitMessageHandlers.downloadImage.postMessage
      ) {
        return new Promise((resolve, reject) => {
          downloadImagePromises[imgUrl] = { resolve, reject };
          deps.webkitMessageHandlers.downloadImage.postMessage({
            imgUrl,
            openImageOnSuccess,
            imageDownloadCallbackPathAndName,
          });
        });
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        status: "failure",
        errorCode: "FEATURE_NOT_SUPPORTED",
      });
    },

    downloadImageResult(imgUrl, isSuccessful, errorCode) {
      const promise = downloadImagePromises[imgUrl];
      if (promise !== undefined) {
        if (isSuccessful) {
          promise.resolve({
            status: "success",
          });
        } else {
          promise.reject({
            status: "failure",
            errorCode,
          });
        }
      }
    },

    iosCallbacks: {
      wishlistChanged(apiInstance, messageHandlers) {
        messageHandlers.wishlistChanged.postMessage({});
      },
      basketChanged(apiInstance, messageHandlers) {
        messageHandlers.basketChanged.postMessage({});
      },
      async getAppTrackingTransparencyStatus(messageHandlers) {
        if (
          messageHandlers &&
          messageHandlers.getAppTrackingTransparencyStatus &&
          messageHandlers.getAppTrackingTransparencyStatus.postMessage
        ) {
          return messageHandlers.getAppTrackingTransparencyStatus.postMessage({});
        }
        return null;
      },
      grantPriceAlertPermission(
        apiInstance,
        messageHandlers,
        frontendToken,
        grantedCallbackPathAndName,
        notGrantedCallbackPathAndName,
      ) {
        messageHandlers.grantPriceAlertPermission.postMessage({
          frontendToken,
          grantedCallbackPathAndName,
          notGrantedCallbackPathAndName,
        });
      },
      collectArticleDetails(apiInstance, messageHandlers, data) {
        messageHandlers.collectArticleDetails.postMessage(data);
      },
      onSearchTermReceived(messageHandlers, data) {
        messageHandlers.onSearchTermReceived.postMessage(data);
      },

      paypalRedirect(apiInstance, messageHandlers, data) {
        messageHandlers.paypalRedirect.postMessage(data);
      },
      trackIDFASuccess(apiInstance, messageHandlers, success) {
        messageHandlers.trackIDFASuccess.postMessage(success);
      },
      trackAdjust(messageHandlers, data) {
        if (
          messageHandlers &&
          messageHandlers.trackAdjust &&
          messageHandlers.trackAdjust.postMessage
        ) {
          messageHandlers.trackAdjust.postMessage(data);
        }
      },
      trackFirebase(apiInstance, messageHandlers, data) {
        messageHandlers.trackFirebase.postMessage(data);
      },
      shareNative(apiInstance, messageHandlers, data) {
        if (messageHandlers.shareNative) {
          messageHandlers.shareNative.postMessage(data);
        } else if (deps.navigator && deps.navigator.share) {
          deps.navigator.share({
            url: data.url,
            title: "Gefunden auf otto.de",
            text: "Gerade bei OTTO gefunden:",
          });
        }
      },
      onOrderConfirmed(apiInstance, messageHandlers) {
        messageHandlers.onOrderConfirmed.postMessage({});
      },
      onConsentGenerationFinish(apiInstance, messageHandlers) {
        messageHandlers.onConsentGenerationFinish.postMessage({});
      },
      onCookieBannerResurface(apiInstance, messageHandlers) {
        messageHandlers.onCookieBannerResurface.postMessage({});
      },
      onInvalidOneTrustVendorList(messageHandlers, exceptionMessage) {
        messageHandlers.onInvalidOneTrustVendorList.postMessage(exceptionMessage);
      },
      onOpenPreferenceCenter(messageHandlers) {
        messageHandlers.onOpenPreferenceCenter.postMessage({});
      },
      onOptOutAllVendors(messageHandlers) {
        messageHandlers.onOptOutAllVendors.postMessage({});
      },
      onOptOutGeneralVendor(vendorId, messageHandlers) {
        messageHandlers.onOptOutGeneralVendor.postMessage(vendorId);
      },
      onOptOutIabVendor(vendorId, messageHandlers) {
        messageHandlers.onOptOutIabVendor.postMessage(vendorId);
      },
      onOptOutGoogleVendor(vendorId, messageHandlers) {
        messageHandlers.onOptOutGoogleVendor.postMessage(vendorId);
      },
      updateAdjustSetting(ottoConsentGiven, googleConsentGiven, messageHandlers) {
        messageHandlers.updateAdjustSetting.postMessage([ottoConsentGiven, googleConsentGiven]);
      },
    },

    registerEventHandlers() {
      if (this.runningInApp()) {
        document.addEventListener("popstate", function () {
          api.extractFocusedMetaTags();
        });
        if (this.appType() === ftapps.platform.ios) {
          deps.document.addEventListener("wishlist.changed", () => {
            this.iosCallbacks.wishlistChanged(this, deps.webkitMessageHandlers);
          });
          deps.document.addEventListener("basket.changed", () => {
            this.iosCallbacks.basketChanged(this, deps.webkitMessageHandlers);
          });
        }

        if (this.appType() === ftapps.platform.android && deps.appsAndroid) {
          if (deps.appsAndroid.basketAmountChanged && deps.appsAndroid.wishlistAmountChanged) {
            // new app version supports separate events in javascript callbacks
            deps.document.addEventListener("basket.changed", () => {
              deps.appsAndroid.basketAmountChanged();
            });
            deps.document.addEventListener("wishlist.changed", () => {
              deps.appsAndroid.wishlistAmountChanged();
            });
          }
        }
      }
    },

    registerEventQBus() {
      if (deps.eventQBus !== undefined && this.runningInApp()) {
        deps.eventQBus.on("ft-apps.firebase.eventTracking", (data) => {
          this.trackFirebase(data);
        });
        deps.eventQBus.on("ft1.order-core.orderSuccessful", () => {
          this.onOrderConfirmed();
        });
        deps.eventQBus.on("apps.cookie-banner.resurface", () => {
          this.onCookieBannerResurface();
        });
        deps.eventQBus.on("ft-apps.adjust.eventTracking", async (data) => {
          await this.trackAdjust(data);
        });
      }
    },

    /**
     * @alias module:o_apps.runningInApp
     * @returns {boolean} indicating if we are currently running in the otto app
     */
    runningInApp() {
      return this.splitCookies().filter((cookie) => cookie === "app=true").length === 1;
    },

    splitCookies() {
      return deps.document.cookie.split("; ");
    },

    grantPriceAlertPermission(
      frontendToken,
      grantedCallbackPathAndName,
      notGrantedCallbackPathAndName,
    ) {
      if (
        this.appType() === ftapps.platform.android &&
        deps.appsAndroid &&
        deps.appsAndroid.grantPriceAlertPermission
      ) {
        deps.appsAndroid.grantPriceAlertPermission(
          frontendToken,
          grantedCallbackPathAndName,
          notGrantedCallbackPathAndName,
        );
      } else if (this.appType() === ftapps.platform.ios) {
        this.iosCallbacks.grantPriceAlertPermission(
          this,
          deps.webkitMessageHandlers,
          frontendToken,
          grantedCallbackPathAndName,
          notGrantedCallbackPathAndName,
        );
      }
    },

    collectArticleDetails() {
      const productJsonElement = deps.document.getElementById(`product_data_json`);

      if (productJsonElement) {
        const productJsonText = productJsonElement.innerText;
        const productJson = JSON.parse(productJsonText);

        if (productJson.image.length >= 1) {
          const [firstImage] = productJson.image;
          productJson.image = firstImage;
        }

        productJson.url = productJson.offers.url;

        if (productJson.aggregateRating?.reviewCount !== undefined) {
          productJson.aggregateRating.ratingCount = productJson.aggregateRating.reviewCount;
        }

        if (this.appType() === ftapps.platform.ios) {
          this.iosCallbacks.collectArticleDetails(this, deps.webkitMessageHandlers, productJson);
        } else {
          return JSON.stringify(productJson);
        }
      }

      return null;
    },

    paypalRedirect(data) {
      const { url } = data;
      if (
        api.appType() === ftapps.platform.android &&
        deps.appsAndroid &&
        deps.appsAndroid.paypalRedirect &&
        url
      ) {
        deps.appsAndroid.paypalRedirect(url);
      } else if (api.appType() === ftapps.platform.ios && url) {
        this.iosCallbacks.paypalRedirect(this, deps.webkitMessageHandlers, data);
      }
    },

    closePaypal(data) {
      const { url } = data;
      if (url) {
        if (api.runningInApp()) {
          deps.htmlLocation.href = url;
        } else {
          deps.htmlLocation.href = url.replace("https://", "otto://");
        }
      }
    },

    trackGAID() {
      // no-op because old apps don't honor google policy, see https://qspa.otto.de/jira/browse/PAPP-12167
    },

    trackGAIDHonoringPreference(gaid) {
      if (!this.cmp.isOttoPersonalisationAllowed()) {
        return;
      }
      try {
        deps.lhotseExactag.data.apps = { gaid };
        deps.exactag.track(deps.lhotseExactag.data);
      } catch (e) {
        if (api.appType() === ftapps.platform.android && deps.appsAndroid) {
          deps.appsAndroid.reportTrackGAIDFailure(e);
        }
      }
    },

    trackIDFA(idfa) {
      const isAppTrackingTransparencyDenied = idfa === "00000000-0000-0000-0000-000000000000";
      if (
        this.appType() !== ftapps.platform.ios ||
        !this.cmp.isOttoPersonalisationAllowed() ||
        isAppTrackingTransparencyDenied
      ) {
        return;
      }

      try {
        deps.lhotseExactag.data.apps = { idfa };
        deps.exactag.track(deps.lhotseExactag.data);
        this.iosCallbacks.trackIDFASuccess(this, deps.webkitMessageHandlers, true);
      } catch (e) {
        this.iosCallbacks.trackIDFASuccess(this, deps.webkitMessageHandlers, false);
      }
    },

    async trackAdjust(adjustPayload) {
      if (
        adjustPayload !== null &&
        typeof adjustPayload === "object" &&
        this.runningInApp() &&
        (await isAdjustTrackingAllowed())
      ) {
        if (
          this.appType() === ftapps.platform.android &&
          deps.appsAndroid &&
          deps.appsAndroid.trackAdjust
        ) {
          const adjustPayloadJSON = JSON.stringify(adjustPayload);
          deps.appsAndroid.trackAdjust(adjustPayloadJSON);
        } else if (this.appType() === ftapps.platform.ios) {
          this.iosCallbacks.trackAdjust(deps.webkitMessageHandlers, adjustPayload);
        }
      }
    },

    trackFirebase(firebasePayloadJSON) {
      if (
        firebasePayloadJSON !== null &&
        typeof firebasePayloadJSON === "object" &&
        this.runningInApp()
      ) {
        shouldPassFirebaseEventToClients(api).then((shouldPass) => {
          if (shouldPass) {
            const eventParams = firebasePayloadJSON.event_params;
            const error = {};

            if (!(deps.firebaseEventNameKey in firebasePayloadJSON)) {
              error.event_name = "event name is missing";
              firebasePayloadJSON.validation_error = error;
              callFirebaseBridge(api, firebasePayloadJSON);
              return;
            }

            validateFirebaseParams(eventParams, error);

            if (Object.getOwnPropertyNames(error).length !== 0) {
              firebasePayloadJSON.validation_error = error;
            }

            callFirebaseBridge(api, firebasePayloadJSON);
          }
        });
      }
    },
    onOrderConfirmed() {
      if (
        api.appType() === ftapps.platform.android &&
        deps.appsAndroid &&
        deps.appsAndroid.onOrderConfirmed
      ) {
        deps.appsAndroid.onOrderConfirmed();
      } else if (api.appType() === ftapps.platform.ios) {
        api.iosCallbacks.onOrderConfirmed(api, deps.webkitMessageHandlers);
      }
    },
    wishlist: {
      onPriceAlertPermissionGranted() {
        if (
          deps.wishlist &&
          deps.wishlist() &&
          deps.wishlist().priceAlert &&
          deps.wishlist().priceAlert.Service &&
          typeof deps.wishlist().priceAlert.Service.onPermissionGranted === "function"
        ) {
          deps.wishlist().priceAlert.Service.onPermissionGranted();
        }
      },
      onPriceAlertPermissionDeclined() {
        if (
          deps.wishlist &&
          deps.wishlist() &&
          deps.wishlist().priceAlert &&
          deps.wishlist().priceAlert.Service &&
          typeof deps.wishlist().priceAlert.Service.onPermissionDenied === "function"
        ) {
          deps.wishlist().priceAlert.Service.onPermissionDenied();
        }
      },
    },
    cmp: {
      acceptConsents() {
        if (deps.cookieBanner && deps.cookieBanner() && deps.cookieBanner().accept) {
          deps
            .cookieBanner()
            .accept()
            .then(
              () => consentGenerationBridge(api),
              () => consentGenerationBridge(api),
            );
          return true;
        }
        return false;
      },
      rejectConsents() {
        if (deps.cookieBanner && deps.cookieBanner() && deps.cookieBanner().reject) {
          deps
            .cookieBanner()
            .reject()
            .then(
              () => consentGenerationBridge(api),
              () => consentGenerationBridge(api),
            );
          return true;
        }
        return false;
      },
      onDataProtectionPageInteractionSuccess() {
        if (deps.privacyPage && deps.privacyPage() && deps.privacyPage().handleAppDseSuccess) {
          deps.privacyPage().handleAppDseSuccess();
          return true;
        }
        return false;
      },
      onDataProtectionPageInteractionError(errorMessage) {
        if (deps.privacyPage && deps.privacyPage() && deps.privacyPage().handleAppDseError) {
          deps.privacyPage().handleAppDseError(errorMessage);
          return true;
        }
        return false;
      },
      triggerConsentSynchronisation(consentOfGeneralVendors, consentId, iabString) {
        const consentOfGeneralVendorsType = Object.prototype.toString.call(consentOfGeneralVendors);
        if (consentOfGeneralVendors !== null && consentOfGeneralVendorsType !== "[object Object]") {
          onInvalidOneTrustVendorListBridge(
            api,
            `Invalid general vendors parameter detected. Received type is '${consentOfGeneralVendorsType}'. Expected type '[object Object]' or null`,
          );
          return;
        }

        if (consentId !== null && typeof consentId !== "string") {
          onInvalidOneTrustVendorListBridge(
            api,
            `Invalid consent id parameter detected. Received type is '${typeof consentId}'. Expected type 'string' or null`,
          );
          return;
        }

        if (iabString !== null && typeof iabString !== "string") {
          onInvalidOneTrustVendorListBridge(
            api,
            `Invalid iab string parameter detected. Received type is '${typeof iabString}'. Expected type 'string' or null`,
          );
          return;
        }

        if (!(deps.cmp && deps.cmp() && deps.cmp().processAppConsentV2)) {
          return;
        }

        let vendorIdWithConsentState = null;
        if (consentOfGeneralVendors) {
          vendorIdWithConsentState = {};
          Object.keys(consentOfGeneralVendors).forEach((vendorId) => {
            const vendorObject = consentOfGeneralVendors[vendorId];
            if (vendorObject && Object.prototype.hasOwnProperty.call(vendorObject, "consent")) {
              vendorIdWithConsentState[vendorId] = vendorObject.consent;
            } else {
              onInvalidOneTrustVendorListBridge(
                api,
                `Invalid consent object for vendor with id '${vendorId}' was detected. The vendor object value was: '${JSON.stringify(
                  vendorObject,
                )}'`,
              );
              vendorIdWithConsentState[vendorId] = 0;
            }
          });
        }

        deps
          .cmp()
          .processAppConsentV2(consentId, vendorIdWithConsentState, iabString)
          .then((result) => {
            if (result) {
              this.onCookieBannerConsentUpdated();
              this.updateAdjustConsent();
            }
          });
      },
      async updateAdjustConsent() {
        await Promise.all([
          this.isOttoPersonalisationAllowed(),
          this.isGoogleTCFAllowed(),
          this.isGoogleRemarketingAllowed(),
        ]).then(([ottoConsent, googleTCFConsent, googleRemarketingConsent]) => {
          const googleConsentGiven = googleTCFConsent && googleRemarketingConsent;
          this.updateAdjustSetting(ottoConsent, googleConsentGiven);
        });
      },
      updateAdjustSetting(ottoConsentGiven, googleConsentGiven) {
        if (
          api.appType() === ftapps.platform.android &&
          deps.appsAndroid &&
          deps.appsAndroid.updateAdjustSetting
        ) {
          deps.appsAndroid.updateAdjustSetting(ottoConsentGiven, googleConsentGiven);
        } else if (
          api.appType() === ftapps.platform.ios &&
          api.iosCallbacks &&
          api.iosCallbacks.updateAdjustSetting &&
          deps.webkitMessageHandlers &&
          deps.webkitMessageHandlers.updateAdjustSetting
        ) {
          api.iosCallbacks.updateAdjustSetting(
            ottoConsentGiven,
            googleConsentGiven,
            deps.webkitMessageHandlers,
          );
        }
      },
      onCookieBannerConsentUpdated() {
        if (
          api.appType() === ftapps.platform.android &&
          deps.appsAndroid &&
          deps.appsAndroid.onCookieBannerConsentUpdated
        ) {
          deps.appsAndroid.onCookieBannerConsentUpdated();
        }
      },
      openPreferenceCenter() {
        if (
          api.appType() === ftapps.platform.android &&
          deps.appsAndroid &&
          deps.appsAndroid.onOpenPreferenceCenter
        ) {
          deps.appsAndroid.onOpenPreferenceCenter();
        } else if (
          api.appType() === ftapps.platform.ios &&
          api.iosCallbacks &&
          api.iosCallbacks.onOpenPreferenceCenter &&
          deps.webkitMessageHandlers &&
          deps.webkitMessageHandlers.onOpenPreferenceCenter
        ) {
          api.iosCallbacks.onOpenPreferenceCenter(deps.webkitMessageHandlers);
        }
      },
      optOutAll() {
        optOutAllForOneTrust(api);
      },
      optOutGeneralVendor(vendorId) {
        if (
          api.appType() === ftapps.platform.android &&
          deps.appsAndroid &&
          deps.appsAndroid.onOptOutGeneralVendor
        ) {
          deps.appsAndroid.onOptOutGeneralVendor(vendorId);
        } else if (
          api.appType() === ftapps.platform.ios &&
          api.iosCallbacks &&
          api.iosCallbacks.onOptOutGeneralVendor &&
          deps.webkitMessageHandlers &&
          deps.webkitMessageHandlers.onOptOutGeneralVendor
        ) {
          api.iosCallbacks.onOptOutGeneralVendor(vendorId, deps.webkitMessageHandlers);
        }
      },
      optOutIabVendor(vendorId) {
        if (
          api.appType() === ftapps.platform.android &&
          deps.appsAndroid &&
          deps.appsAndroid.onOptOutIabVendor
        ) {
          deps.appsAndroid.onOptOutIabVendor(vendorId);
        } else if (
          api.appType() === ftapps.platform.ios &&
          api.iosCallbacks &&
          api.iosCallbacks.onOptOutIabVendor &&
          deps.webkitMessageHandlers &&
          deps.webkitMessageHandlers.onOptOutIabVendor
        ) {
          api.iosCallbacks.onOptOutIabVendor(vendorId, deps.webkitMessageHandlers);
        }
      },
      optOutGoogleVendor(vendorId) {
        if (
          api.appType() === ftapps.platform.android &&
          deps.appsAndroid &&
          deps.appsAndroid.onOptOutGoogleVendor
        ) {
          deps.appsAndroid.onOptOutGoogleVendor(vendorId);
        } else if (
          api.appType() === ftapps.platform.ios &&
          api.iosCallbacks &&
          api.iosCallbacks.onOptOutGoogleVendor &&
          deps.webkitMessageHandlers &&
          deps.webkitMessageHandlers.onOptOutGoogleVendor
        ) {
          api.iosCallbacks.onOptOutGoogleVendor(vendorId, deps.webkitMessageHandlers);
        }
      },

      isOttoPersonalisationAllowed() {
        if (deps.cmp && deps.cmp() && deps.cmp().getOttoOwnPurposesConsent) {
          return deps.cmp().getOttoOwnPurposesConsent();
        }
        return false;
      },

      isGoogleTCFAllowed() {
        if (deps.cmp && deps.cmp() && deps.cmp().readVendorsConsents) {
          return deps
            .cmp()
            .readVendorsConsents()
            .then((consentMap) => {
              return consentMap instanceof Map && !!consentMap.get(755);
            });
        }
        return false;
      },

      isGoogleRemarketingAllowed() {
        if (deps.cmp && deps.cmp() && deps.cmp().getConsentForGV) {
          return !!deps.cmp().getConsentForGV("GOREM");
        }
        return false;
      },
    },
    onCookieBannerResurface() {
      if (
        api.appType() === ftapps.platform.android &&
        deps.appsAndroid &&
        deps.appsAndroid.onCookieBannerResurface
      ) {
        deps.appsAndroid.onCookieBannerResurface();
      } else if (api.appType() === ftapps.platform.ios) {
        api.iosCallbacks.onCookieBannerResurface(api, deps.webkitMessageHandlers);
      }
    },
    async getAppTrackingTransparencyStatus() {
      return api.iosCallbacks.getAppTrackingTransparencyStatus(deps.webkitMessageHandlers);
    },
    updateExactagStatus() {
      const MIN_IOS_VERSION = 14;

      if (api.appType() === ftapps.platform.ios && api.osVersionIos() >= MIN_IOS_VERSION) {
        // iOS App will check users ATT and Cookie Banner response, and emit the event if needed
        return;
      }

      if (deps.eventQBus !== undefined) {
        deps.eventQBus.emit("ft-apps.tracking.userATTOptInStatusChanged", true);
      }
    },
    async canDisplay3D() {
      return Promise.resolve(false);
    },
    onViewIn3DButtonClicked() {
      // purposely left empty to disable feature
    },
  };

  if (featureToggle.shareNative && api.runningInApp()) {
    if (api.appType() === ftapps.platform.ios) {
      /**
       * Share a url through the operating systems native sharing feature.
       * @alias module:o_apps.shareNative
       * @param {object} data - The data to share
       * @param {string} data.url - A url containing a valid URL that can be opened in the app, others do not work. See
       * {@link https://github.com/otto-ec/apps_backend_config/blob/main/lambda-payload/src/main/java/provider/config/HostWhitelist.kt|HostWhitelist.kt}
       * for the current list of accepted domains.
       * @param {string} data.type - *optional* can be either 'other' or 'product'. Defaults to 'other'. A string
       * encoding the type of content to be shared. Yields a context specific text for the shared message.
       * @example
       * // shares current page
       * o_apps.shareNative({ url: window.location.toString() });
       * // alternative
       * o_apps.shareNative({ url: window.location.toString(), type: 'product' });
       * @example
       * // share a product page
       * o_apps.shareNative({ url: 'https://www.otto.de/p/apple-iphone-13-pro-max-smartphone-17-cm-6-7-zoll-128-gb-speicherplatz-12-mp-kamera-1503513681/#variationId=1503513682', type: 'product');
       */
      api.shareNative = (data) => {
        if (data.type === null) {
          data.type = "";
        }
        api.iosCallbacks.shareNative(api, deps.webkitMessageHandlers, data);
      };
    } else if (api.appType() === ftapps.platform.android) {
      api.shareNative = (data) => {
        if (deps.appsAndroid) {
          deps.appsAndroid.shareNative(data.url, data.type);
        }
      };
    }
  }

  api.extractFocusedMetaTags();
  api.registerEventHandlers();
  api.registerEventQBus();
  api.updateSearchTerm();
  api.updateExactagStatus();

  return api;
};
